import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../../components/PaginationNav.vue'
import moment from 'moment'
import LoadingComp from '../../../components/LoadingComp.vue'

import * as config from '@/config.json'

export default {
  name: 'PushCampaignList',
  components: {
    Loading,
    PaginationNav,
    LoadingComp
  },
  data: function () {
    return {
      isLoading: false,
      token: null,
      selectedOptionApp: null,
      optionsApp: [],
      campaigns: [],
      fieldsCampaigns: [
        {
          key: 'imageUrl',
          label: 'Imagem'
        },
        {
          key: 'id',
          label: 'Id'
        },
        {
          key: 'title',
          label: 'Título'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'createdAt',
          label: 'Data Geração'
        },
        {
          key: 'sentAt',
          label: 'Data Envio'
        },
        {
          key: 'isSent',
          label: 'Enviado'
        },
        {
          key: 'customersSelected',
          label: 'Qtde Clientes'
        },
        {
          key: 'showDetails',
          label: ''
        }
      ],
      currentPage: 1,
      perPage: 6,

      pagination: {},
      paginationRange: 11,

      readPushes: 0,
      receivedPushes: 0,
      unReceivedPushes: 0,
      unReadPushes: 0,
      sentPushes: 0,
      totalPushes: 0,

      selectedOptionFilter: null,
      optionsFilter: [
        {
          value: null,
          text: 'Selecione'
        },
        {
          value: 1,
          text: 'Título'
        },
        {
          value: 2,
          text: 'Descrição'
        }
      ],

      selectedOptionDate: null,
      optionsDate: [
        {
          value: null,
          text: 'Selecione o Tipo'
        },
        {
          value: 0,
          text: 'Geração'
        },
        {
          value: 1,
          text: 'Envio'
        }
      ],

      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),

      filter: '',
      detailsCampaign: [],
      fieldsDetailsCampaign: [
        {
          key: 'customerId',
          label: 'ID Cliente'
        },
        {
          key: 'customerName',
          label: 'Cliente'
        },
        {
          key: 'isSent',
          label: 'Enviado'
        },
        {
          key: 'isFailed',
          label: 'Falhou'
        },
        {
          key: 'isVisualized',
          label: 'Visualizado'
        },
        {
          key: 'deviceName',
          label: 'Dispositivo'
        }
      ],
      campaignImage: null,
      titleCampaign: null,
      descriptionCampaign: null,
      pagePagination: null,
      totalRecords: 0,
      isBusy: false,
      isBusyDetailsCampaign: false
    }
  },
  methods: {
    async reloadStartDate() {
      this.startDate = moment(new Date()).format('YYYY-MM-DD')
      await this.searchCampaigns(this.currentPage)
    },

    async reloadEndDate() {
      this.endDate = moment(new Date()).format('YYYY-MM-DD')
      await this.searchCampaigns(this.currentPage)
    },

    clearApps() {
      this.selectedOptionApp = null
      this.searchCampaigns(this.currentPage)
    },

    async getApps() {
      const url = `${config.default.urlBase}/apps/user/v1`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsApp = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          )

          if (this.optionsApp.length == 1) {
            if (!this.selectedOptionApp) {
              this.selectedOptionApp = this.optionsApp[0].value
            }
          }

          if (this.selectedOptionApp) {
            if (!this.pagePagination) {
              await this.searchCampaigns(this.currentPage)
            } else {
              await this.navigate(this.pagePagination)
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsApp = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          this.$alert(`Ocorreu um erro ao buscar os aplicativos! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async clearLocalStoragePage() {
      localStorage.removeItem('selectedOptionDateCompany')
      localStorage.removeItem('startDateCompany')
      localStorage.removeItem('endDateCompany')
      localStorage.removeItem('selectedOptionFilterCompany')
      localStorage.removeItem('filterCompany')
      localStorage.removeItem('currentPageCampaignCompany')
    },

    async redirect(ref) {
      if (ref == 'push-campaign-manager') {
        localStorage.removeItem('redirectCampaign')
        localStorage.setItem('redirectCampaign', 'push-campaign-list')
      } else {
        await this.clearLocalStoragePage()
      }
      this.$router.push(ref)
    },

    focus(id) {
      if (id === 'filter') {
        this.filter = ''
      }
      document.getElementById(id).focus()
    },

    cleanSearch() {
      this.filter = ''
      this.searchCampaigns(this.currentPage)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async navigate(page) {
      this.searchCampaigns(page)
    },

    async changeFilter() {
      if (this.selectedOptionFilter != null) {
        await this.searchCampaigns(this.currentPage)
      }
    },

    async showDetails(coupon) {
      await this.getDetailsCampaign(coupon.id)

      this.campaignImage = coupon.imageUrl ? coupon.imageUrl : 'http://img.accesys.com.br/produtos/semimagem.png'
      this.titleCampaign = coupon.title
      this.descriptionCampaign = coupon.description

      if (this.detailsCampaign.length > 0) {
        await this.$bvModal.show('detailsCampaign')
      }
    },

    async getDetailsCampaign(id) {
      this.isBusyDetailsCampaign = true

      const url = `${config.default.urlBase}/campaigns/${id}/details`

      try {
        const response = await axios({
          url,
          method: 'GET',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusyDetailsCampaign = false

          this.detailsCampaign = response.data
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusyDetailsCampaign = false
        this.detailsCampaign = []

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar os detalhes da campanha! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    buildParams(appId, page, perPage, optionDate, startDate, endDate, optionFilter, filter) {
      let params = new Object()

      params.appId = appId

      if (startDate && endDate) {
        let initialDate = moment(startDate).format('YYYY-MM-DD 00:00:00')
        let finalDate = moment(endDate).format('YYYY-MM-DD 23:59:59')

        if (!initialDate || !finalDate) {
          return null
        }

        if (finalDate < initialDate) {
          this.getNotification('Aviso', 'A data final deve ser maior que a data inicial!', 'warning')

          return null
        } else {
          if (optionDate == 0) {
            params.startDate = initialDate
            params.endDate = finalDate
            params.dateType = 0
          } else if (optionDate == 1) {
            params.startDate = initialDate
            params.endDate = finalDate
            params.dateType = 1
          }
        }
      }

      if (optionFilter == 1) {
        params.title = filter
      } else if (optionFilter == 2) {
        params.description = filter
      }

      params.pageSize = perPage
      params.page = page

      return params
    },

    async searchCampaigns(page) {
      this.isBusy = true

      const url = `${config.default.urlBase}/campaigns/search`
      const buildParams = this.buildParams(
        this.selectedOptionApp,
        page,
        this.perPage,
        this.selectedOptionDate,
        this.startDate,
        this.endDate,
        this.selectedOptionFilter,
        this.filter
      )

      localStorage.setItem('selectedOptionDateCompany', this.selectedOptionDate)
      localStorage.setItem('startDateCompany', this.startDate)
      localStorage.setItem('endDateCompany', this.endDate)
      localStorage.setItem('selectedOptionFilterCompany', this.selectedOptionFilter)
      localStorage.setItem('filterCompany', this.filter)
      localStorage.setItem('currentPageCampaignCompany', page)

      try {
        const response = await axios({
          url,
          method: 'GET',
          params: buildParams,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusy = false

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          this.campaigns = response.data.items

          this.readPushes = response.data.readPushes
          this.receivedPushes = response.data.receivedPushes
          this.unReceivedPushes = response.data.unReceivedPushes
          this.unReadPushes = response.data.unReadPushes
          this.sentPushes = response.data.sentPushes
          this.totalPushes = response.data.sentPushes
          this.totalRecords = response.data.records
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false
        this.isLoading = false

        this.campaigns = []
        this.pagination = []
        this.readPushes = 0
        this.receivedPushes = 0
        this.unReceivedPushes = 0
        this.unReadPushes = 0
        this.sentPushes = 0
        this.totalPushes = 0
        this.totalRecords = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as campanhas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')

    this.selectedOptionDate = localStorage.getItem('selectedOptionDateCompany') ? localStorage.getItem('selectedOptionDateCompany') : null
    this.startDate = localStorage.getItem('startDateCompany') ? localStorage.getItem('startDateCompany') : moment(new Date()).format('YYYY-MM-DD')
    this.endDate = localStorage.getItem('endDateCompany') ? localStorage.getItem('endDateCompany') : moment(new Date()).format('YYYY-MM-DD')
    this.selectedOptionFilter = localStorage.getItem('selectedOptionFilterCompany') ? localStorage.getItem('selectedOptionFilterCompany') : null
    this.filter = localStorage.getItem('filterCompany') ? localStorage.getItem('filterCompany') : ''

    this.pagePagination = parseInt(localStorage.getItem('currentPageCampaignCompany'))

    if (!this.pagePagination) {
      await this.searchCampaigns(this.currentPage)
    } else {
      await this.navigate(this.pagePagination)
    }

    await this.getApps()

    this.isLoading = false
  }
}
