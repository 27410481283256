import { saveAs } from 'file-saver'

export function valueOrDefault(field, defaultValue) {
  return field ? field : defaultValue
}

export function parseNumber(number) {
  if (number) {
    return parseInt(valueOrDefault(number, 0))
  }
  return 0
}

export function parseBoolean(field) {
  if (field == 'true') {
    return true
  } else {
    return false
  }
}

export function parseFieldBoolean(field) {
  if (field == 'true') {
    return true
  } else {
    return false
  }
}

export function parseBooleanToInteger(field) {
  if (field == 'true') {
    return 1
  } else {
    return 0
  }
}

export function formatCurrencyBRL(value) {
  return valueOrDefault(value, 0).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  })
}

export function base64ToArrayBuffer(base64String) {
  const stringLength = base64String.length - 'data:image/png;base64,'.length
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
  const sizeInMb = sizeInBytes / (1024 * 1024)

  return sizeInMb
}

export async function downloadXLSX(urlExport, fileName) {
  try {
    const response = await fetch(urlExport, {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
    const blob = await response.blob()
    const xlsxBlob = new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    saveAs(xlsxBlob, fileName)
  } catch (error) {
    console.error('Erro ao baixar o arquivo XLSX:', error)
  }
}

export async function openFile(url, error) {
  try {
    if (url != null && url != '' && url != 'undefined') {
      window.open(url, '_blank')
    } else if (!error) {
      await this.$alert('Dado não disponível!', 'Aviso', 'warning')
    }
  } catch (error) {
    await this.$alert('Erro ao baixar o arquivo!', 'Aviso', 'warning')
  }
}

export async function copyToClipboard(text) {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text)
      console.log('Texto copiado com sucesso!')
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      console.log('Texto copiado com fallback!')
    }
  } catch (erro) {
    console.error('Erro ao copiar para a área de transferência:', erro)
  }
}
