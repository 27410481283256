import { render, staticRenderFns } from "./OrderItems.html?vue&type=template&id=f34d9480&scoped=true&external"
import script from "./OrderItems.js?vue&type=script&lang=js&external"
export * from "./OrderItems.js?vue&type=script&lang=js&external"
import style0 from "./OrderItems.scss?vue&type=style&index=0&id=f34d9480&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f34d9480",
  null
  
)

export default component.exports