import { render, staticRenderFns } from "./ProductsList.html?vue&type=template&id=efc45606&scoped=true&external"
import script from "./ProductsList.js?vue&type=script&lang=js&external"
export * from "./ProductsList.js?vue&type=script&lang=js&external"
import style0 from "./ProductsList.scss?vue&type=style&index=0&id=efc45606&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efc45606",
  null
  
)

export default component.exports