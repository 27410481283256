<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <span class="page-item" :class="{ disabled: source.page == 1 || source.page == 0 || source.page == null }">
        <a class="page-link" href="#" @click="navigate($event, source.page - 1)">Anterior</a>
      </span>

      <span class="page-item" v-for="(page, index) in pages" :key="index" track-by="$index" :class="{ active: source.page == page }">
        <span class="page-link" v-if="page == '...'">{{ page }}</span>
        <a class="page-link" href="#" v-if="page != '...'" @click="navigate($event, page)">{{ page }}</a>
      </span>

      <span class="page-item" :class="{ disabled: source.page == source.pages }">
        <a class="page-link" href="#" @click="nextPrev($event, source.page)">Próxima</a>
      </span>
    </ul>
  </nav>
</template>
<script>
export default {
  props: ['source', 'disabled'],

  data() {
    return {
      pages: [],
      index: null
    }
  },

  watch: {
    source() {
      this.pages = this.generatePagesArray(this.source.page, this.source.records, this.source.rowsPerPage, this.source.paginationRange)
    }
  },

  methods: {
    generatePagesArray: function (currentPage, collectionLength, rowsPerPage, paginationRange) {
      var pages = []
      var totalPages = Math.ceil(collectionLength / rowsPerPage)
      var halfWay = Math.ceil(paginationRange / 2)
      var position

      if (currentPage <= halfWay) {
        position = 'start'
      } else if (totalPages - halfWay < currentPage) {
        position = 'end'
      } else {
        position = 'middle'
      }

      var ellipsesNeeded = paginationRange < totalPages
      var i = 1
      while (i <= totalPages && i <= paginationRange) {
        var pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages)
        var openingEllipsesNeeded = i === 2 && (position === 'middle' || position === 'end')
        var closingEllipsesNeeded = i === paginationRange - 1 && (position === 'middle' || position === 'start')
        if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
          pages.push('...')
        } else {
          pages.push(pageNumber)
        }
        i++
      }
      return pages
    },

    calculatePageNumber: function (i, currentPage, paginationRange, totalPages) {
      var halfWay = Math.ceil(paginationRange / 2)
      if (i === paginationRange) {
        return totalPages
      } else if (i === 1) {
        return i
      } else if (paginationRange < totalPages) {
        if (totalPages - halfWay < currentPage) {
          return totalPages - paginationRange + i
        } else if (halfWay < currentPage) {
          return currentPage - halfWay + i
        } else {
          return i
        }
      } else {
        return i
      }
    },

    nextPrev(ev, page) {
      page = parseInt(page) + 1
      if (page == 1 || page == parseInt(this.source.pages) + 1) {
        return
      }
      this.navigate(ev, page)
    },
    navigate(ev, page) {
      ev.preventDefault()
      this.$emit('navigate', page)
    }
  }
}
</script>