import Loading from "vue-loading-overlay";
import axios from "axios";

import * as config from "@/config.json";

export default {
  name: "BannersAppList",
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      isBusy: false,
      token: null,
      currentPage: 1,
      perPage: 3,
      selectedOptionCompany: null,
      optionsCompany: [],
      bannersCompanyUser: [],
      fieldsCompany: [{
          key: "id",
          label: "Id",
        },
        {
          key: "url",
          label: "Banner",
        },
        {
          key: "action",
          label: "",
        }
      ],
    }
  },

  methods: {
    redirect(ref) {
      if (ref == 'banners-app-manager') {
        localStorage.removeItem('redirectBannersApp');
        localStorage.setItem('redirectBannersApp', 'banners-app-list');
      } else {
        localStorage.removeItem('selectedOptionCompanyAppBanner');
      }
      this.$router.push(ref);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async deleteBanner(banner) {
      const res = await this.$swal({
        title: "Exclusão",
        text: `Confirma a exclusão do banner ${banner.item.id}?`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#303030",
        cancelButtonColor: "#fe5c3a",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (res.isConfirmed) {
        await this.destroyBanner(this.selectedOptionCompany, banner.item.id);
      }
    },

    async destroyBanner(appId, id) {
      const url = `${config.default.urlBase}/apps/${appId}/banners/${id}`;

      try {
        const response = await axios({
          url,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            "Banner excluído!",
            "Sucesso",
            "success"
          );
          await this.getBanners();
        }

      } catch (error) {
        this.isLoading = false;

        console.log({
          error
        })

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro deletar o banner! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getBanners() {
      this.isBusy = true;

      const url = `${config.default.urlBase}/apps/banners`;

      localStorage.setItem('selectedOptionCompanyAppBanner', this.selectedOptionCompany);

      try {
        const response = await axios({
          url,
          method: "GET",
          params: {
            appId: this.selectedOptionCompany,
          },
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.isBusy = false;

          this.bannersCompanyUser = response.data;

          if (this.bannersCompanyUser.length <= 0) {
            this.getNotification(
              "Aviso",
              `Nenhum banner encontrado com os filtros informados!`,
              "warning"
            );
          }
        }

      } catch (error) {
        this.isBusy = false;

        console.log({
          error
        });

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getApps() {
      const url = `${config.default.urlBase}/apps/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value;
            }
          }

          if (this.selectedOptionCompany) {
            await this.getBanners();
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.optionsCompany = [];
        this.isLoading = false;


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  computed: {
    rows() {
      return this.bannersCompanyUser.length;
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    this.selectedOptionApp = parseInt(localStorage.getItem('selectedOptionCompanyAppBanner') ? localStorage.getItem('selectedOptionCompanyAppBanner') : null);

    await this.getApps();
    this.isLoading = false;
  }
}