import PictureInput from "vue-picture-input";
import Loading from "vue-loading-overlay";
import axios from "axios";

import * as config from "@/config.json";

export default {
  name: "BannersTotemManager",
  components: {
    PictureInput,
    Loading
  },
  data: () => {
    return {
      isLoading: false,
      selectedLocalExibicao: null,
      localExibicao: [{
          value: 0,
          text: "Aplicativo",
        },
        {
          value: 1,
          text: "Totem",
        },
        {
          value: 2,
          text: "Aplicativo e Totem",
        },
      ],
      selectedOptionCompany: null,
      optionsCompany: [],
      url: null,
      token: null,
      sites: [],
      fieldsSites: [{
          key: "selected",
          label: "Selecionada"
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "cnpj",
          label: "Cnpj",
        },
        {
          key: "fantasy",
          label: "Nome"
        }
      ],
      fieldsSitesMobile: [{
          key: "selected",
          label: ""
        },
        {
          key: "cnpj",
          label: "Cnpj",
        },
        {
          key: "fantasy",
          label: "Nome"
        }
      ],
      currentPage: 1,
      perPage: 10,
      selectMode: 'multi',
      selectModeMobile: 'multi',
      selected: [],
      image: null,
      dataVerified: false,
    }
  },
  methods: {
    onChange(image) {
      if (image) {
        this.image = image;
      }
    },

    redirect() {
      const ref = localStorage.getItem("redirectBanners") ? localStorage.getItem("redirectBanners") : "home";

      localStorage.removeItem("redirectBanners");
      this.$router.push(ref);
    },

    onRowSelected(items) {
      if (items.length > 0) {
        this.selected = items;
      } else {
        this.selected = [];
      }
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      this.$refs.selectableTableMobile.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.$refs.selectableTableMobile.clearSelected()
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    verifyDataBanner(data, field) {
      if (!data || !data.length > 0) {
        this.getNotification(
          "Aviso",
          `Preencha o campo ${field}`,
          "warning"
        );
        return false;
      }
      return true;
    },

    async buildDataBanner(image, sites) {
      const arraySites = sites.map(site => site.id);

      const sitesId = new Array(arraySites.join(","));

      if (this.verifyDataBanner(image, "Imagem") && this.verifyDataBanner(sitesId, "Divisões")) {
        this.dataVerified = true;
        return {
          image,
          sitesId
        }
      } else {
        this.dataVerified = false;
      }
    },

    async createBanner() {
      this.isLoading = true;
      const url = `${config.default.urlBase}/sites/banners`;

      const data = await this.buildDataBanner(this.image, this.selected);

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status === 200) {
          this.isLoading = false;

          await this.$alert(
            "Banner cadastrado!",
            "Sucesso",
            "success"
          );

          this.$refs.selectableTable.clearSelected();
          this.$refs.selectableTableMobile.clearSelected();
          this.$refs.pictureInput.removeImage();

          this.$router.go()
        }
      } catch (error) {
        console.log({
          error
        })
        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          if (this.dataVerified) {
            await this.$alert(
              `Ocorreu um erro ao criar o banner! ${error.response.data.message}`,
              "Aviso",
              "warning"
            );
            this.dataVerified = false;
          }
        }
      }
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value;
            await this.getSitesByCompany(this.selectedOptionCompany);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.sites = response.data;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.sites = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    }
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getCompanies();
    this.isLoading = false;
  }
}