import Loading from "vue-loading-overlay";

export default {
  name: "StockBacklogs",

  components: {
    Loading
  },

  data() {
    return {
      isLoading: false,
      loader: "bars",
      opacity: 0.9,

      totalSent: 0,
      checkedCachedCompanies: '',
      offlineWebhooks: [],
      onlineWebhooks: []
    };
  },

  methods: {

    redirect(route) {
      this.$router.push(route)
    },

    async readParams() {
      this.checkedCachedCompanies = this.$route.params.checkedCachedCompanies
      this.offlineWebhooks = this.$route.params.offlineWebhooks
      this.onlineWebhooks = this.$route.params.onlineWebhooks
      this.totalSent = this.$route.params.counter
    }
  },

  beforeMount() {
    this.readParams()
  }
}