import { render, staticRenderFns } from "./SalesList.html?vue&type=template&id=5d3f3e55&scoped=true&external"
import script from "./SalesList.js?vue&type=script&lang=js&external"
export * from "./SalesList.js?vue&type=script&lang=js&external"
import style0 from "./SalesList.scss?vue&type=style&index=0&id=5d3f3e55&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d3f3e55",
  null
  
)

export default component.exports