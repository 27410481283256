import Loading from 'vue-loading-overlay'
import PaginationNav from '../../../components/PaginationNav.vue'
import PaginationMobile from '../../../components/PaginationMobile.vue'
import axios from 'axios'

import * as config from '@/config.json'
import moment from 'moment/moment'

export default {
  name: 'DeviceHistory',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },
  data: () => {
    return {
      isLoading: false,
      token: null,
      selectedOptionAplication: null,
      optionsAplications: [],
      selectedOptionSite: null,
      search: null,
      optionsSites: [],
      pagination: {},
      paginationRange: 11,
      deviceHistory: [],
      fieldsDeviceHistory: [
        {
          key: 'siteName',
          label: 'Loja'
        },
        {
          key: 'openDate',
          label: 'Data'
        },
        {
          key: 'deviceDescription',
          label: 'Dispositivo'
        },
        {
          key: 'deviceStatus',
          label: 'Status'
        },
        {
          key: 'deviceNotes',
          label: 'Observação'
        },
        {
          key: 'flowDescription',
          label: 'Ação'
        },
        {
          key: 'customerName',
          label: 'Nome Cliente'
        },
        {
          key: 'customerDocument',
          label: 'Documento Cliente'
        },
        {
          key: 'customerPhone',
          label: 'Telefone Cliente'
        },
        {
          key: 'customerEmail',
          label: 'Email Cliente'
        }
      ],
      currentPage: 1,
      perPage: 10,
      selectedOption: null,
      options: [
        {
          value: null,
          text: '--- Selecione ---'
        },
        {
          value: 0,
          text: 'Data Abertura'
        },
        {
          value: 1,
          text: 'Nome Cliente'
        },
        {
          value: 2,
          text: 'Documento Cliente'
        }
      ],
      startDate: null,
      endDate: null,
      selectedOptionDevice: null,
      optionsDevices: [],
      totalRecords: 0,
      selectedOptionCompany: null,
      optionsCompany: [],
      isBusy: false
    }
  },
  methods: {
    async navigate(page) {
      await this.getDeviceHistory(page)
    },

    async clearCompany() {
      this.selectedOptionCompany = null
    },

    async clearSites() {
      this.selectedOptionSite = null
      this.getDeviceHistory(this.currentPage)
    },

    async clearDevices() {
      this.selectedOptionDevice = null
      this.getDeviceHistory(this.currentPage)
    },

    async clearSearch() {
      this.search = null
      this.getDeviceHistory(this.currentPage)
    },

    focus(id) {
      if (id === 'filterInput') {
        this.search = ''
      }

      document.getElementById(id).focus()
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value
            }

            this.getSitesByUserType(this.selectedOptionCompany)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getSitesByUserType() {
      const url = `${config.default.urlBase}/sites/user/type`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsSites = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} - ${item.name}`
              }
            })
          )

          if (this.optionsSites.length == 1) {
            this.selectedOptionSite = this.optionsSites[0].value

            await this.getDevicesBySiteId()
          }
        }
      } catch (error) {
        console.log({
          error
        })

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getDevicesBySiteId() {
      const url = `${config.default.urlBase}/devices/site/${this.selectedOptionSite}`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsDevices = await Promise.all(
            response.data.map(item => {
              return {
                value: item.deviceId,
                text: item.description
              }
            })
          )

          if (this.optionsDevices.length == 1) {
            this.selectedOptionDevice = this.optionsDevices[0].value
          }
        }
      } catch (error) {
        console.log({
          error
        })

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }
      }
    },

    async onChangeFilter() {
      this.startDate = null
      this.endDate = null
      this.search = null

      if (this.selectedOption == '0') {
        this.focus('startDate')
      }

      if (this.selectedOption == '1' || this.selectedOption == '2') {
        this.focus('filterInput')
      }

      await this.getDeviceHistory(this.currentPage)
    },

    async buildData(companyId, siteId, deviceId, selectedOption, page) {
      const data = {}

      if (companyId) {
        data.companyId = companyId
      }

      if (siteId) {
        data.siteId = siteId
      }

      if (deviceId) {
        data.deviceId = deviceId
      }

      if (selectedOption == 0) {
        if (this.startDate && this.endDate) {
          data.startDate = moment(this.startDate).format('YYYY-MM-DD 00:00:00')
          data.endDate = moment(this.endDate).format('YYYY-MM-DD 23:59:59')
        }
      }

      if (selectedOption == 1) {
        if (this.search) {
          data.customerName = this.search
        }
      }

      if (selectedOption == 2) {
        if (this.search) {
          data.customerDocument = this.search
        }
      }

      data.page = page
      data.pageSize = this.perPage

      return data
    },

    async getDeviceHistory(page) {
      this.isBusy = true

      const url = `${config.default.urlBase}/devices/history/search`

      try {
        const response = await axios({
          url,
          method: 'post',
          data: await this.buildData(this.selectedOptionCompany, this.selectedOptionSite, this.selectedOptionDevice, this.selectedOption, page),
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusy = false

          this.deviceHistory = response.data.items

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          this.totalRecords = response.data.records
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false

        this.deviceHistory = []
        this.totalRecords = 0
        this.pagination = {}

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    redirect(ref) {
      this.$router.push(ref)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')

    this.getCompanies()
    this.getSitesByUserType()
    this.isLoading = false
  }
}
