<template>
  <div class="loading-spinner">
    <div class="spinner"></div>
    <span class="loading"> Carregando...</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.9;
}

.spinner {
  margin-top: 15px;
  border: 2px solid #333;
  border-left-color: transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  opacity: 0.9;
}

.loading {
  margin-top: 15px;
  margin-left: 10px;
  color: #333;
  font-size: 11px;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  opacity: 0.9;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
