import Loading from "vue-loading-overlay";
import axios from "axios";
import * as config from "@/config.json";


export default {
  name: "BotTalk",
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOption: null,
      options: [],
      botTalk: '',
      botTalkDescription: '',
    }
  },

  methods: {
    redirect() {
      const ref = localStorage.getItem("redirectBot") ? localStorage.getItem("redirectBot") : "home";

      localStorage.removeItem("redirectBot");
      this.$router.push(ref);
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value;
            this.getSpeechs();
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSpeechs() {
      const url = `${config.default.urlBase}/speechs/types`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.options = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.description,
            };
          }));

          if (this.options.length == 1) {
            this.selectedOption = this.options[0].value;
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.options = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os tipos de fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async buildDataCreate(description, text, companyId, typeId) {
      return {
        description,
        text,
        companyId,
        typeId
      }
    },

    async createSpeech() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/speech`;
      const data = await this.buildDataCreate(this.botTalkDescription, this.botTalk, this.selectedOptionCompany, this.selectedOption);

      try {
        const response = await axios({
          url,
          method: "post",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          const res = await this.$swal({
            title: "Sucesso",
            text: `Fala do totem cadastrada! Deseja ir para a tela de vínculo?`,
            icon: "success",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: "#303030",
            cancelButtonColor: "#fe5c3a",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          });

          if (res.value) {
            this.$router.push('/bot-bound');
          }

          this.selectedOption = null;
          this.botTalkDescription = '';
          this.botTalk = '';
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao cadastrar a fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getCompanies();
    await this.getSpeechs();
    this.isLoading = false;
  }
}