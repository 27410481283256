import { render, staticRenderFns } from "./ProductsSite.html?vue&type=template&id=095274b8&scoped=true&external"
import script from "./ProductsSite.js?vue&type=script&lang=js&external"
export * from "./ProductsSite.js?vue&type=script&lang=js&external"
import style0 from "./ProductsSite.scss?vue&type=style&index=0&id=095274b8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095274b8",
  null
  
)

export default component.exports