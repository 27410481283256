import { render, staticRenderFns } from "./BannersManager.html?vue&type=template&id=42751115&scoped=true&external"
import script from "./BannersManager.js?vue&type=script&lang=js&external"
export * from "./BannersManager.js?vue&type=script&lang=js&external"
import style0 from "./BannersManager.scss?vue&type=style&index=0&id=42751115&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42751115",
  null
  
)

export default component.exports