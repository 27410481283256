import { render, staticRenderFns } from "./PromotionsList.html?vue&type=template&id=d893b63c&scoped=true&external"
import script from "./PromotionsList.js?vue&type=script&lang=js&external"
export * from "./PromotionsList.js?vue&type=script&lang=js&external"
import style0 from "./PromotionsList.scss?vue&type=style&index=0&id=d893b63c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d893b63c",
  null
  
)

export default component.exports