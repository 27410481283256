import Loading from "vue-loading-overlay";

import axios from "axios";
import * as config from "@/config.json";
import HorizontalStepper from 'vue-stepper';

// Steps
import CouponInformation from "./Steps/CouponInformation/1_CouponInformation.vue";
import SelectApps from "./Steps/SelectApps/2_SelectApps.vue";
import SelectCustomers from "./Steps/SelectCustomers/3_SelectCustomers.vue";
import moment from "moment";

export default {
  name: "DiscountCouponManager",
  components: {
    Loading,
    HorizontalStepper,
    CouponInformation,
    SelectApps,
    SelectCustomers
  },
  data: function () {
    return {
      isLoading: false,
      token: null,
      titulo: null,
      descricao: null,
      isjornalzinho: null,
      steps: [{
          icon: "assignment",
          name: "coupon-information",
          title: "Informações Cupom",
          component: CouponInformation,
          completed: false
        },
        {
          icon: "apps",
          name: "select-apps",
          title: "Selecionar Aplicativos",
          component: SelectApps,
          completed: false
        },
        {
          icon: "group",
          name: "select-customers",
          title: "Selecionar Clientes",
          component: SelectCustomers,
          completed: false
        },
      ],

      appsIdCouponDiscount: "",
      startDateCouponDiscount: null,
      titleCouponDiscount: "",
      descriptionCouponDiscount: "",
      expiry: false,
      expiryAtCouponDiscount: null,
      typeCouponDiscount: null,
      typeCampaign: 1,
      valueCouponDiscount: 0,
      idCustomersCouponDiscount: [],
      startTime: null,
      endTime: null,
      push: false,
      paramsCampaign: {},
      sitesId: null,
      birthDateStart: null,
      birthDateEnd: null,
      isReusable: false
    };
  },
  watch: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async redirect() {
      await this.clearLocalStorage();
      await this.clearCustomers();

      const ref = localStorage.getItem("redirectDiscount") ? localStorage.getItem("redirectDiscount") : "home";
      localStorage.removeItem("redirectDiscount");
      this.$router.push(ref);
    },

    async clearCustomers() {
      this.customersSelected = [];
      this.customersIdCampaign = [];
    },

    cancelLoading() {
      this.isLoading = false;
    },

    // Executed when @completed-step event is triggered
    completeStep(payload) {
      this.isLoading = true;

      this.steps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },

    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.steps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async clearLocalStorage() {
      localStorage.removeItem("appsId-CouponsDiscount");

      localStorage.removeItem("startDateCouponDiscount");
      localStorage.removeItem("startTimeCouponDiscount");

      localStorage.removeItem("titleCouponDiscount");
      localStorage.removeItem("descriptionCouponDiscount");

      localStorage.removeItem("expiry");
      localStorage.removeItem("expiryAtCouponDiscount");
      localStorage.removeItem("endTimeCouponDiscount");

      localStorage.removeItem("typeCouponDiscount");
      localStorage.removeItem("valueCouponDiscount");

      localStorage.removeItem("idCustomersCouponDiscount");

      localStorage.removeItem("sitesIdCampaign");

      localStorage.removeItem("push");

      localStorage.removeItem("isReusable");

      localStorage.removeItem("birthDateStart");
      localStorage.removeItem("birthDateEnd");
    },

    async getLocalStorage() {
      this.appsIdCouponDiscount = localStorage.getItem("appsId-CouponsDiscount");
      this.startDateCouponDiscount = localStorage.getItem("startDateCouponDiscount");
      this.titleCouponDiscount = localStorage.getItem("titleCouponDiscount");
      this.descriptionCouponDiscount = localStorage.getItem("descriptionCouponDiscount");
      this.expiry = localStorage.getItem("expiry") == 'true' ? true : localStorage.getItem("expiry") == 'false' ? false : false;
      this.expiryAtCouponDiscount = localStorage.getItem("expiryAtCouponDiscount");
      this.typeCouponDiscount = parseInt(localStorage.getItem("typeCouponDiscount"));
      this.valueCouponDiscount = parseFloat(localStorage.getItem("valueCouponDiscount"));
      this.idCustomersCouponDiscount = localStorage.getItem("idCustomersCouponDiscount");
      this.startTime = localStorage.getItem("startTimeCouponDiscount");
      this.endTime = localStorage.getItem("endTimeCouponDiscount");
      this.push = localStorage.getItem("push") == 'true' ? true : false
      this.sitesId = localStorage.getItem("sitesIdCampaign");
      this.birthDateStart = localStorage.getItem("birthDateStart");
      this.birthDateEnd = localStorage.getItem("birthDateEnd");
      this.isReusable = localStorage.getItem("isReusable") == 'true' ? true : localStorage.getItem("isReusable") == 'false' ? false : false
    },

    async buildData(appsId, sitesId, startDate, title, description, expiry, expiryAt, type, value, isFirstRegistration, customersId, startTime, endTime) {
      const data = new Object();

      data.appsId = new Array(appsId);

      if (sitesId) {
        const sitesIdArray = new Array(sitesId);
        data.sitesId = sitesIdArray
      } else {
        data.sitesId = new Array();
      }

      data.startDate = moment(`${startDate} ${startTime}:00`).format("YYYY-MM-DD HH:mm:ss");
      data.title = title;
      data.description = description;

      if (expiry) {
        data.expiryAt = moment(`${expiryAt} ${endTime}:00`).format("YYYY-MM-DD HH:mm:ss");
      }

      data.type = parseInt(type);
      data.value = value;
      data.isFirstRegistration = isFirstRegistration == 'true' ? true : isFirstRegistration == 'false' ? false : false;
      data.customersId = customersId ? new Array(customersId) : new Array();
      data.isReusable = this.isReusable

      return data;
    },

    async buildParamsCampaign(title, description, type, redirectId, appsId, sitesId, customersId, birthDateStart, birthDateEnd, refDiscountCoupon, allowedSendCampaign) {
      const siteIds = sitesId ? new Array(sitesId) : null;

      this.paramsCampaign = {
        title,
        description,
        type,
        redirectId,
        appsId: new Array(appsId),
        customersId: customersId && customersId.length > 0 ? customersId : new Array(),
        birthDateStart,
        birthDateEnd,
        refDiscountCoupon,
        allowedSendCampaign
      }

      if (siteIds) {
        this.paramsCampaign.sitesId = siteIds
      }

    },

    async finalizar(payload) {
      this.isLoading = true;

      await this.getLocalStorage();

      const url = `${config.default.urlBase}/coupons`;
      const data = await this.buildData(
        this.appsIdCouponDiscount,
        this.sitesId,
        this.startDateCouponDiscount,
        this.titleCouponDiscount,
        this.descriptionCouponDiscount,
        this.expiry,
        this.expiryAtCouponDiscount,
        this.typeCouponDiscount,
        this.valueCouponDiscount,
        false,
        this.idCustomersCouponDiscount,
        this.startTime,
        this.endTime,
        this.isReusable
      );

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          await this.clearLocalStorage();

          if (!this.push) {
            await this.$alert("Cupom de desconto cadastrado!", "Sucesso", "success");
            this.redirect();
          } else if (this.push) {
            await this.buildParamsCampaign(this.titleCouponDiscount, this.descriptionCouponDiscount, this.typeCampaign, this.appsIdCouponDiscount, this.appsIdCouponDiscount, this.sitesId, this.idCustomersCouponDiscount, this.birthDateStart, this.birthDateEnd, true, true);

            this.$router.push({
              name: 'PushCampaignManager',
              params: this.paramsCampaign
            });
          } else {
            payload.index = 0
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro gerar o cupom de desconto! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }

      this.$emit("can-continue", {
        value: false
      })

      this.isLoading = false;
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.isLoading = false;
  },
};