import { render, staticRenderFns } from "./BannersList.html?vue&type=template&id=3736b572&scoped=true&external"
import script from "./BannersList.js?vue&type=script&lang=js&external"
export * from "./BannersList.js?vue&type=script&lang=js&external"
import style0 from "./BannersList.scss?vue&type=style&index=0&id=3736b572&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3736b572",
  null
  
)

export default component.exports