import Loading from "vue-loading-overlay";
import axios from "axios";
import * as config from "@/config.json";


export default {
  name: "BotTalkBound",
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      selected: [],
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSpeechs: null,
      optionsSpeechs: [],
      selectMode: 'multi',
      selectModeMobile: 'multi',
      currentPage: 1,
      perPage: 4,
      pos: [],
      fieldsPos: [{
          key: "selected",
          label: "Selecionado"
        },
        {
          key: "number",
          label: "Número",
        },
        {
          key: "fullName",
          label: "Descrição",
        },
        {
          key: "inactive",
          label: "Ativo",
        }
      ],

      fieldsPosMobile: [{
          key: "selected",
          label: ""
        },
        {
          key: "fullName",
          label: "Descrição",
        },
        {
          key: "inactive",
          label: "Ativo",
        }
      ],
    }
  },

  methods: {
    redirect() {
      const ref = localStorage.getItem("redirectBotBound") ? localStorage.getItem("redirectBotBound") : "home";

      localStorage.removeItem("redirectBotBound");
      this.$router.push(ref);
    },

    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectablePos.selectAllRows()
      this.$refs.selectablePosMobile.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectablePos.clearSelected()
      this.$refs.selectablePosMobile.clearSelected()
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async buildDataBound(speechId, idsPOS) {
      const arrayPOS = idsPOS.map(idPOS => idPOS.id);

      const posIds = new Array(arrayPOS.join(","));
      return {
        speechId,
        posIds
      }
    },

    async boundSpeech() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/speech/bound`;
      const data = await this.buildDataBound(this.selectedOptionSpeechs, this.selected);

      try {
        const response = await axios({
          url,
          method: "post",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          await this.$alert(
            `Fala do totem vinculada!`,
            "Sucesso",
            "success"
          );

          this.pos = [];
          this.selectedOptionSpeechs = [];

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao cadastrar a fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSpeechs() {
      const url = `${config.default.urlBase}/companies/speechs`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.optionsSpeechs = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.description,
            };
          }));

          if (this.optionsSpeechs.length == 1) {
            this.selectedOptionSpeechs = this.optionsSpeechs[0].value;
            this.getPosSpeechs(this.selectedOptionSpeechs);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.options = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os tipos de fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getPosSpeechs(id) {
      const url = `${config.default.urlBase}/speechs/${id}/pos/unbounded`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.clearSelected();
          this.pos = [];

          this.pos = response.data;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.pos = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os totens permitidos para vínculo de fala! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getSpeechs();
    this.isLoading = false;
  }
}