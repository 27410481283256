import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../components/PaginationNav.vue'
import PaginationMobile from '../../components/PaginationMobile.vue'
import LoadingComp from '../../components/LoadingComp.vue'

import * as config from '@/config.json'

export default {
  name: 'ProductsList',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  props: ['source'],
  data: () => {
    return {
      permissions: '',
      isAllowedEditProduct: false,
      isAllowedChangeStockProduct: false,
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 8,
      product: null,
      token: null,
      items: [],
      fields: [
        {
          key: 'isInactive',
          label: 'Ativo'
        },
        {
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'groupImageUrl',
          label: 'Foto Grupo'
        },
        {
          key: 'groupDescription',
          label: 'Grupo'
        },
        {
          key: 'availableQuantity',
          label: 'Estoque'
        },
        {
          key: 'unity',
          label: 'Un'
        },
        {
          key: 'minimumQuantity',
          label: 'Qtd Mínima'
        },
        {
          key: 'maximumQuantity',
          label: 'Qtd Máxima'
        },
        {
          key: 'idealQuantity',
          label: 'Qtd Ideal'
        },
        {
          key: 'costPrice',
          label: 'Custo'
        },
        {
          key: 'salePrice',
          label: 'Valor'
        },
        {
          key: 'promotionPrice',
          label: 'Valor Promocional'
        },
        {
          key: 'loyalPrice',
          label: 'Valor Fidelizado'
        },
        {
          key: 'isBlockedUnderEighteen',
          label: 'Classificação'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      itemsCompany: [],
      fieldsCompany: [
        {
          key: 'isInactive',
          label: 'Ativo'
        },
        {
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'unity',
          label: 'Un'
        },
        {
          key: 'imageGroupUrl',
          label: 'Foto Grupo'
        },
        {
          key: 'groupDescription',
          label: 'Grupo'
        },
        {
          key: 'isBlockedUnderEighteen',
          label: 'Classificação'
        },
        {
          key: 'details',
          label: ''
        }
      ],
      details: [],
      fieldsDetailsProduct: [
        {
          key: 'siteId',
          label: 'Id Loja'
        },
        {
          key: 'site',
          label: 'Loja'
        },
        {
          key: 'availableQuantity',
          label: 'Estoque'
        },
        {
          key: 'unity',
          label: 'Un'
        },
        {
          key: 'maximumQuantity',
          label: 'Qtd Máxima'
        },
        {
          key: 'minimumQuantity',
          label: 'Qtd Mínima'
        },
        {
          key: 'idealQuantity',
          label: 'Qtd Ideal'
        },
        {
          key: 'costPrice',
          label: 'Custo'
        },
        {
          key: 'salePrice',
          label: 'Valor'
        },
        {
          key: 'promotionPrice',
          label: 'Valor Promocional'
        },
        {
          key: 'loyalPrice',
          label: 'Valor Fidelizado'
        },
        {
          key: 'isInactive',
          label: 'Ativo'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      options: [
        {
          value: null,
          text: '--- Selecione ---'
        },
        {
          value: 1,
          text: 'Descrição'
        },
        {
          value: 2,
          text: 'Barras'
        },
        {
          value: 3,
          text: 'Unidade'
        }
      ],

      isBlockedUnderEighteen: false,
      isLoading: false,
      selectedOption: null,
      selectedOptionCompany: null,
      optionsCompany: [],
      typeUser: null,
      totalRows: 0,
      image: null,
      description: null,
      unity: null,
      totalRecords: 0,
      selectedProductsRadio: 0,
      optionsSelectProductsRadio: [
        {
          item: 0,
          name: 'Todos'
        },
        {
          item: 1,
          name: 'Ativos'
        },
        {
          item: 2,
          name: 'Inativos'
        }
      ],

      selectedProductsClassificationRadio: 0,
      optionsSelectClassificationProductsRadio: [
        {
          item: 0,
          name: 'Todos'
        },
        {
          item: 1,
          name: 'Livre'
        },
        {
          item: 2,
          name: '18 -'
        }
      ],

      stockProduct: 0,
      stockAdjust: 0,
      currentStockProduct: 0,
      maximumQuantityProduct: 0,
      minimumQuantityProduct: 0,
      idealQuantityProduct: 0,
      costProduct: 0,
      valueProduct: 0,
      promotionPriceProduct: 0,
      loyalPriceProduct: 0,
      inactiveProduct: false,
      state: null,
      stateStockAdjustment: null,
      productSiteId: null,
      productSelected: [],
      selectedOptionUnity: null,
      optionsUnity: [
        {
          value: null,
          text: '--- Selecione ---'
        },
        {
          value: 1,
          text: 'UN'
        },
        {
          value: 2,
          text: 'KG'
        },
        {
          value: 3,
          text: 'LT'
        }
      ],
      isBusy: false,
      isBusyDetails: false,
      selectedReason: null,
      optionsReasons: [{}],
      reasonStockAdjustmentId: null
    }
  },
  methods: {
    redirect(ref) {
      if (ref == 'Cadastro') {
        this.$router.push(`/add-product`)
      }
      if (ref == 'Home') {
        this.$router.push(`/home`)
      }
    },

    async getReasonsStockAdjustment() {
      try {
        const url = `${config.default.urlBase}/stock-adjustment`

        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsReasons = await Promise.all(
            response.data.reasons.map(item => {
              return {
                value: item.id,
                text: `${item.id} - ${item.description} - [${item.type}]`
              }
            })
          )
        }
      } catch (error) {
        console.log({ error })
      }
    },

    closeModalReasonStockAdjustment() {
      this.$refs.reasonStockAdjustment.hide()

      this.isAllowedChangeStockProduct = false
      this.selectedReason = null
    },

    confirmReasonStockAdjustment() {
      this.reasonStockAdjustmentId = this.selectedReason
      this.isAllowedChangeStockProduct = false

      this.currentStockProduct = parseFloat(this.stockProduct)
      this.stockProduct = parseFloat(this.stockAdjust)
      this.stockAdjust = null

      this.$refs.reasonStockAdjustment.hide()

      this.selectedReason = null
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async closeModalEditProduct() {
      await this.$refs.editProduct.hide()

      if (this.details.length > 0) {
        await this.showDetailsProduct(this.productSelected)
      } else {
        await this.getProducts(this.currentPage)
      }
    },

    async showEditProduct(product) {
      this.productSiteId = product.productSiteId
      this.inactiveProduct = product.isInactive == 1 ? true : false

      this.stockProduct = product.availableQuantity.replace(',', '.')
      this.stockProduct = this.stockProduct == 0 ? '0' : this.stockProduct ? parseFloat(this.stockProduct) : '0'

      this.costProduct = product.costPrice.replace('R$', '').trim()
      this.costProduct = this.costProduct.replace(',', '.')
      this.costProduct = parseFloat(this.costProduct)

      this.valueProduct = product.salePrice.replace('R$', '').trim()
      this.valueProduct = this.valueProduct.replace(',', '.')
      this.valueProduct = this.valueProduct == 0 ? '0' : this.valueProduct ? parseFloat(this.valueProduct) : '0'

      this.promotionPriceProduct = product.promotionPrice.replace('R$', '').trim()
      this.promotionPriceProduct = this.promotionPriceProduct.replace(',', '.')
      this.promotionPriceProduct = parseFloat(this.promotionPriceProduct)

      this.loyalPriceProduct = product.loyalPrice.replace('R$', '').trim()
      this.loyalPriceProduct = this.loyalPriceProduct.replace(',', '.')
      this.loyalPriceProduct = parseFloat(this.loyalPriceProduct)

      this.maximumQuantityProduct = product.maximumQuantity.replace(',', '.').trim()
      this.maximumQuantityProduct = parseFloat(this.maximumQuantityProduct)

      this.minimumQuantityProduct = product.minimumQuantity.replace(',', '.').trim()
      this.minimumQuantityProduct = parseFloat(this.minimumQuantityProduct)

      this.idealQuantityProduct = product.idealQuantity.replace(',', '.').trim()
      this.idealQuantityProduct = parseFloat(this.idealQuantityProduct)

      this.description = product.description
      this.unity = product.unity

      this.isBlockedUnderEighteen = product.isBlockedUnderEighteen

      this.isAllowedChangeStockProduct = false
      this.selectedReason = null

      this.$refs.editProduct.show()
    },

    async showDetailsProduct(product) {
      this.productSelected = product

      if (product.imageUrl) {
        this.image = product.imageUrl
      } else {
        this.image = 'http://img.accesys.com.br/produtos/semimagem.png'
      }

      this.description = product.description
      this.unity = product.unity
      this.isBlockedUnderEighteen = product.isBlockedUnderEighteen
      await this.getDetailsProduct(product.id)

      if (this.details.length > 0) {
        this.$refs.detailsProduct.show()
      } else {
        this.getNotification('Aviso', 'Nenhum detalhe do produto foi encontrado', 'warning')
      }
    },

    cleanSearch() {
      this.product = ''
      this.getProducts(this.currentPage)
    },

    navigate(page) {
      this.getProducts(page)
    },

    async allowedChangeStockProduct() {
      this.isAllowedChangeStockProduct = true

      await this.getReasonsStockAdjustment()

      this.stockAdjust = this.stockProduct

      this.$refs.reasonStockAdjustment.show()
    },

    buildParams(company, option, selectedProductsRadio, selectedClassificationRadio, pagina) {
      let params = new Object()

      if (company != null) {
        params.companyId = company
      }

      if (option == 1) {
        params.description = this.product
      }

      if (option == 2) {
        params.sku = this.product
      }

      if (option == 3) {
        if (this.selectedOptionUnity != null) {
          params.unity = this.optionsUnity[this.selectedOptionUnity].text
        }
      }

      if (selectedProductsRadio == 1) {
        params.isInactive = false
      }

      if (selectedProductsRadio == 2) {
        params.isInactive = true
      }

      if (selectedClassificationRadio == 1) {
        params.isBlockedUnderEighteen = false
      }

      if (selectedClassificationRadio == 2) {
        params.isBlockedUnderEighteen = true
      }

      params.pageSize = this.perPage
      params.page = pagina

      return params
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value
            this.getProducts(this.currentPage)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getProducts(pagina) {
      this.isBusy = true

      const url = `${config.default.urlBase}/sites/products/search`
      const params = this.buildParams(
        this.selectedOptionCompany,
        this.selectedOption,
        this.selectedProductsRadio,
        this.selectedProductsClassificationRadio,
        pagina
      )

      this.productSelected = []

      try {
        const response = await axios({
          url: url,
          method: 'get',
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          if (this.typeUser == 'site') {
            const products = await Promise.all(
              response.data.items.map(async product => {
                return {
                  ...product,
                  availableQuantity: String(product.availableQuantity).replace('.', ',').trim(),
                  maximumQuantity: String(product.maximumQuantity).replace('.', ',').trim(),
                  minimumQuantity: String(product.minimumQuantity).replace('.', ',').trim(),
                  idealQuantity: String(product.idealQuantity).replace('.', ',').trim()
                }
              })
            )
            this.items = products
          } else {
            this.itemsCompany = response.data.items
          }

          this.isBusy = false
          this.isLoading = false

          this.totalRows = response.data.records
          this.totalRecords = response.data.records
        }
      } catch (error) {
        console.error({
          errorMessage: `[GetProducts] ${error}`
        })

        this.isBusy = false

        this.items = []
        this.itemsCompany = []
        this.pagination = []
        this.totalRecords = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar os produtos! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getDetailsProduct(id) {
      this.isBusyDetails = true
      const url = `${config.default.urlBase}/sites/products/${id}/details`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.details = response.data

          this.isBusyDetails = false
        }
      } catch (error) {
        console.error({
          errorMessage: `[GetDetailsProduct] ${error}`
        })

        this.isBusyDetails = false

        this.details = []

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar os detalhes do produto! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    verifyState() {
      if (this.stockProduct >= 0 && this.valueProduct >= 0) return true
      else return false
    },

    async buildDataProduct(
      productSiteId,
      isInactive,
      availableQuantity,
      costPrice,
      salePrice,
      promotionPrice,
      loyalPrice,
      minimumQuantity,
      maximumQuantity,
      idealQuantity,
      isBlockedUnderEighteen,
      reasonId
    ) {
      let data = new Object()

      const availableQuantityStr = availableQuantity
      const costPriceStr = costPrice
      const salePriceStr = salePrice
      const promotionPriceStr = promotionPrice
      const loyalPriceStr = loyalPrice
      const minimumQuantityStr = minimumQuantity
      const maximumQuantityStr = maximumQuantity
      const idealQuantityStr = idealQuantity

      data.productSiteId = parseInt(productSiteId)
      data.isInactive = Boolean(isInactive)
      data.availableQuantity = parseFloat(availableQuantityStr) ? parseFloat(availableQuantityStr) : 0
      data.costPrice = parseFloat(costPriceStr) ? parseFloat(costPriceStr) : 0
      data.salePrice = parseFloat(salePriceStr) ? parseFloat(salePriceStr) : 0
      data.promotionPrice = parseFloat(promotionPriceStr) ? parseFloat(promotionPriceStr) : 0
      data.loyalPrice = parseFloat(loyalPriceStr) ? parseFloat(loyalPriceStr) : 0
      data.minimumQuantity = parseFloat(minimumQuantityStr) ? parseFloat(minimumQuantityStr) : 0
      data.maximumQuantity = parseFloat(maximumQuantityStr) ? parseFloat(maximumQuantityStr) : 0
      data.idealQuantity = parseFloat(idealQuantityStr) ? parseFloat(idealQuantityStr) : 0
      data.isBlockedUnderEighteen = Boolean(isBlockedUnderEighteen)

      if (reasonId) {
        data.reasonId = parseInt(reasonId)
      }

      return data
    },

    focus(id) {
      if (id === 'filterInput') {
        this.product = ''
      }

      this.$nextTick(() => {
        document.getElementById('stockAdjust').select()
      })
    },

    async updateProduct() {
      this.isLoading = true
      const url = `${config.default.urlBase}/sites/products`
      const data = await this.buildDataProduct(
        this.productSiteId,
        this.inactiveProduct,
        this.stockProduct,
        this.costProduct,
        this.valueProduct,
        this.promotionPriceProduct,
        this.loyalPriceProduct,
        this.minimumQuantityProduct,
        this.maximumQuantityProduct,
        this.idealQuantityProduct,
        this.isBlockedUnderEighteen,
        this.reasonStockAdjustmentId
      )

      this.state = this.verifyState()

      try {
        const response = await axios({
          url,
          method: 'PATCH',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          await this.closeModalEditProduct()

          await this.$alert('Produto Atualizado!', 'Sucesso', 'success')
        }
      } catch (error) {
        console.log({
          error
        })

        if (this.currentStockProduct != this.stockProduct) {
          this.stockProduct = parseFloat(this.currentStockProduct)
        }

        this.isLoading = false

        if (error.response.status == 500) {
          await this.$alert(`Ocorreu um erro ao atualizar o produto!`, 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else {
          await this.$alert(error.response.data.message, 'Aviso', 'warning')
        }
      }
    },

    getUserPermissions() {
      this.permissions = JSON.parse(sessionStorage.getItem('permissions'))

      if (this.permissions) {
        const cost_Price = this.permissions.find(permission => permission.command === 'cost_price')

        if (cost_Price) {
          this.isAllowedEditProduct = true
        } else {
          this.isAllowedEditProduct = false
        }
      }

      this.isLoading = false
    },

    validationAdjustment() {
      if (this.selectedReason != null && this.stockAdjust >= 0) {
        return true
      }

      return false
    }
  },

  updated() {
    this.state = this.verifyState()

    this.stateStockAdjustment = this.validationAdjustment()
  },

  computed: {
    stockProductState() {
      return this.stockProduct >= 0 ? true : false
    },

    valueProductState() {
      return this.valueProduct ? true : false
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')
    this.typeUser = localStorage.getItem('typeUser')
    this.state = this.verifyState()

    this.getUserPermissions()
    await this.getCompanies()

    this.isLoading = false
  }
}
