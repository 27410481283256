import Loading from "vue-loading-overlay";
import PictureInput from "vue-picture-input";
import axios from "axios";

import * as config from "@/config.json";


export default {
  name: "PromotionInfo",
  components: {
    Loading,
    PictureInput
  },

  props: ["source"],

  data: () => {
    return {
      token: "",
      isLoading: false,
      url: null,
      imagePromotion: null,
      description: "",
      urlImage: null,
      isSpecial: false,
      isApllied: false,
      isEnded: false,
      isFemale: false,
      isMale: false,
      isMondayActive: false,
      mondayStartTime: "",
      mondayEndTime: "",
      isTuesdayActive: false,
      tuesdayStartTime: "",
      tuesdayEndTime: "",
      isWednesdayActive: false,
      wednesdayStartTime: "",
      wednesdayEndTime: "",
      isThursdayActive: false,
      thursdayStartTime: "",
      thursdayEndTime: "",
      isFridayActive: false,
      fridayStartTime: "",
      fridayEndTime: "",
      isSaturdayActive: false,
      saturdayStartTime: "",
      saturdayEndTime: "",
      isSundayActive: false,
      sundayStartTime: "",
      sundayEndTime: "",
      idPromotion: null,
      companyId: null,
      typeCampaign: 2,
      titlePromotion: 'Promoção',
      startDatePromotion: null,
      endDatePromotion: null,
      detailedDescription: "",

      sitesPromotion: [],
      fieldsSitesPromotion: [{
          key: 'cnpj',
          label: 'CNPJ'
        },
        {
          key: 'siteId',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Nome'
        }
      ],
      consumedProducts: [],
      fieldsConsumedProducts: [{
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'promotionalPrice',
          label: 'Promoção'
        },
        {
          key: 'loyalPrice',
          label: 'Fidelizado'
        }
      ],

      fieldsConsumedProductsSpecial: [{
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
      ],
      wonProducts: [],
      fieldsWonProducts: [{
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        }
      ],
      paramsCampaign: {}
    };
  },
  methods: {

    async buildParamsCampaign(id, title, description, image, type) {
      this.paramsCampaign = {
        id,
        title,
        description,
        image,
        type
      }
    },

    async createCampaign() {
      await this.buildParamsCampaign(this.idPromotion, this.titlePromotion, this.description, this.imagePromotion, this.typeCampaign);

      this.$router.push({
        name: 'PushCampaignManager',
        params: this.paramsCampaign
      });
    },

    redirect(ref) {
      this.$router.push(ref)
    },

    async readParams() {
      this.companyId = this.$route.params.companyId;
      this.idPromotion = this.$route.params.id;
      this.description = this.$route.params.description;
      this.detailedDescription = this.$route.params.detailedDescription;
      this.url = this.$route.params.urlImage;
      this.isSpecial = this.$route.params.isSpecial;
      this.isApllied = this.$route.params.isApllied;
      this.isEnded = this.$route.params.isEnded;
      this.isFemale = this.$route.params.isFemale;
      this.isMale = this.$route.params.isMale;
      this.isMondayActive = this.$route.params.isMondayActive;
      this.mondayStartTime = this.$route.params.mondayStartTime;
      this.mondayEndTime = this.$route.params.mondayEndTime;
      this.isTuesdayActive = this.$route.params.isTuesdayActive;
      this.tuesdayStartTime = this.$route.params.tuesdayStartTime;
      this.tuesdayEndTime = this.$route.params.tuesdayEndTime;
      this.isWednesdayActive = this.$route.params.isWednesdayActive;
      this.wednesdayStartTime = this.$route.params.wednesdayStartTime;
      this.wednesdayEndTime = this.$route.params.wednesdayEndTime;
      this.isThursdayActive = this.$route.params.isThursdayActive;
      this.thursdayStartTime = this.$route.params.thursdayStartTime;
      this.thursdayEndTime = this.$route.params.thursdayEndTime;
      this.isFridayActive = this.$route.params.isFridayActive;
      this.fridayStartTime = this.$route.params.fridayStartTime;
      this.fridayEndTime = this.$route.params.fridayEndTime;
      this.isSaturdayActive = this.$route.params.isSaturdayActive;
      this.saturdayStartTime = this.$route.params.saturdayStartTime;
      this.saturdayEndTime = this.$route.params.saturdayEndTime;
      this.isSundayActive = this.$route.params.isSundayActive;
      this.sundayStartTime = this.$route.params.sundayStartTime;
      this.sundayEndTime = this.$route.params.sundayEndTime;
      this.startDatePromotion = this.$route.params.startDate;
      this.endDatePromotion = this.$route.params.endDate;

      await this.getPromotionDetails(this.idPromotion, this.companyId)
    },

    async onChange(image) {
      if (image) {
        this.imagePromotion = image;
        await this.linkImagePromotion(this.imagePromotion, this.idPromotion);
      }
    },

    async onRemove() {
      this.imagePromotion = '';
      await this.linkImagePromotion(this.imagePromotion, this.idPromotion);
    },

    async buildDataImage(imagePromotion) {
      const data = {};

      data.image = imagePromotion;

      return data;
    },

    async linkImagePromotion(imagePromotion, idPromotion) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/promotions/${idPromotion}/image`;
      const data = await this.buildDataImage(imagePromotion);

      try {
        const response = await axios({
          url,
          method: 'PATCH',
          data,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
      }
    },

    async getPromotionDetails(id, companyId) {
      this.isBusy = true;
      const url = `${config.default.urlBase}/promotions/${id}/details`;

      try {
        const response = await axios({
          url: url,
          method: "GET",
          params: {
            companyId: companyId
          },
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.sitesPromotion = response.data.sitesPromotion;
          this.consumedProducts = response.data.consumedProducts;
          this.wonProducts = response.data.wonProducts;

          this.isBusy = false;
        }

      } catch (error) {
        console.error({
          error
        });

        this.isBusy = false;

        this.sitesPromotion = [];
        this.consumedProducts = [];
        this.wonProducts = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os detalhes da promoção! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    await this.readParams();

    this.isLoading = false;
  },
};