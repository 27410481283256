import * as config from '@/config.json'

import Loading from 'vue-loading-overlay'
import axios from 'axios'

export default {
  name: 'UsersManager',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      userId: null,
      name: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirmed: '',
      state: null,
      redirectUser: '',
      selectedOptionUser: null,
      optionsUser: [
        {
          text: 'Organização',
          value: '1'
        },
        {
          text: 'Empresa',
          value: '2'
        },
        {
          text: 'Loja',
          value: '3'
        }
      ],
      division: null,
      organizationDivision: null,
      companiesByUserType: '',
      sitesByUserType: '',
      token: null,
      selected: [],
      companies: [],
      selectedOptionCompany: null,
      typeUser: '',
      paramsUserPermission: {},
      isSlackNotify: false,
      slackApi: '',
      isTelegramNotify: false,
      telegramChatId: null,
      isAppNotify: false,
      originatingSearch: false,
      inactive: false
    }
  },

  methods: {
    verifyState() {
      if (
        this.name != '' &&
        this.phone != '' &&
        this.password != '' &&
        this.passwordConfirmed != '' &&
        this.email != '' &&
        this.selectedOptionUser != null &&
        this.selectedOptionCompany != null &&
        this.originatingSearch == false
      ) {
        return true
      } else if (
        this.name != '' &&
        this.phone != '' &&
        this.email != '' &&
        this.selectedOptionUser != null &&
        this.selectedOptionCompany != null &&
        this.originatingSearch == true
      ) {
        return true
      } else {
        return false
      }
    },

    redirect() {
      const ref = localStorage.getItem('redirectUser') ? localStorage.getItem('redirectUser') : 'home'
      localStorage.removeItem('redirectUser')
      this.$router.push(ref)
    },

    async filterOptionUser(typeUser) {
      const typeOrganization = 'organization'

      if (typeUser !== typeOrganization) {
        this.optionsUser = this.optionsUser.filter(option => option.value !== '1')
      }
    },

    focus(id) {
      document.getElementById(id).focus()
    },

    async onSelected(userDivisionSelected) {
      if (userDivisionSelected == 1) {
        this.organizationDivision = localStorage.getItem('divisionId') + ' ' + localStorage.getItem('divisionName')
        this.selectedOptionCompany = localStorage.getItem('divisionId')
        // this.division = localStorage.getItem("divisionId")
      } else if (userDivisionSelected == 2) {
        this.companiesByUserType = await this.getCompaniesByUserType()
      } else {
        this.sitesByUserType = await this.getSitesByUserType()
      }
    },

    async getCompaniesByUserType() {
      this.isLoading = true

      const url = `${config.default.urlBase}/companies/user/type`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.companies = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.companies.length == 1) {
            this.selectedOptionCompany = this.companies[0].value
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getSitesByUserType() {
      this.isLoading = true

      const url = `${config.default.urlBase}/sites/user/type`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.companies = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.companies.length == 1) {
            this.selectedOptionCompany = this.companies[0].value
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    verifyPassword(newPassword, confirmedPassword) {
      return newPassword == confirmedPassword ? true : false
    },

    showPassword(id) {
      let password = document.getElementById(id)

      const type = password.type == 'password' ? 'text' : 'password'
      password.type = type

      if (id == 'password') {
        this.focus('password')
      } else {
        this.focus('passwordConfirmed')
      }
    },

    async buildUser(
      userId,
      name,
      phone,
      email,
      password,
      type,
      divisionId,
      isSlackNotify,
      isTelegramNotify,
      isAppNotify,
      originatingSearch,
      inactive
    ) {
      const user = {
        name,
        phone,
        email,
        type: parseInt(type),
        divisionId: parseInt(divisionId),
        isSlackNotify,
        isTelegramNotify,
        isAppNotify
      }

      if (originatingSearch == false) {
        user.password = password
      } else {
        user.id = userId
        user.inactive = inactive
      }

      return {
        user
      }
    },

    async readUserParams() {
      this.userId = this.$route.params.userId ? this.$route.params.userId : null
      this.name = this.$route.params.name ? this.$route.params.name : ''
      this.phone = this.$route.params.phone ? this.$route.params.phone : ''
      this.email = this.$route.params.email ? this.$route.params.email : ''
      this.selectedOptionUser = this.$route.params.type ? this.$route.params.type : null

      await this.onSelected(this.selectedOptionUser)

      this.selectedOptionCompany = this.$route.params.divisionId ? this.$route.params.divisionId : null
      this.isAppNotify = this.$route.params.isAppNotify ? this.$route.params.isAppNotify : false
      this.isSlackNotify = this.$route.params.isSlackNotify ? this.$route.params.isSlackNotify : false
      this.isTelegramNotify = this.$route.params.isTelegramNotify ? this.$route.params.isTelegramNotify : false
      this.originatingSearch = this.$route.params.originatingSearch ? this.$route.params.originatingSearch : false
      this.inactive = this.$route.params.inactive ? this.$route.params.inactive : false
    },

    async CreateOrUpdate() {
      if (this.originatingSearch == false) {
        await this.createUser()
      } else {
        await this.updateUser()
      }
    },

    async createUser() {
      this.isLoading = true

      if (this.password && this.confirmedPassword) {
        const passwordVerified = this.verifyPassword()

        if (!passwordVerified) {
          this.isLoading = false
          await this.$alert('As senhas digitadas não conferem!', 'Error', 'error')
        }
      }

      const url = `${config.default.urlBase}/users`
      const builtUser = await this.buildUser(
        this.userId,
        this.name,
        this.phone,
        this.email,
        this.password,
        this.selectedOptionUser,
        this.selectedOptionCompany,
        this.isSlackNotify,
        this.isTelegramNotify,
        this.isAppNotify,
        this.originatingSearch,
        this.inactive
      )

      try {
        const response = await axios({
          url,
          method: 'post',
          data: builtUser,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200 || response.status == 201) {
          this.isLoading = false

          const res = await this.$swal({
            title: 'Sucesso',
            text: `Usuário cadastrado! Deseja cadastrar as permissões do usuário?`,
            icon: 'success',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonColor: '#303030',
            cancelButtonColor: '#fe5c3a',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          })

          if (res.isConfirmed) {
            localStorage.removeItem('redirectUserPermissions')
            localStorage.setItem('redirectUserPermissions', 'users-manager')
            this.paramsUserPermission = {
              userId: response.data.user.id,
              userName: response.data.user.name,
              page: 'usersManager'
            }

            this.$router.push({
              name: 'ManagerUserPermissions',
              params: this.paramsUserPermission
            })
          } else {
            this.redirect()
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else {
          this.isLoading = false
          await this.$alert(`Falha ao cadastrar o usuário! ${error.response.data.message}`, 'Error', 'error')
        }
        console.log({
          error
        })
      }
    },

    async updateUser() {
      this.isLoading = true

      const url = `${config.default.urlBase}/users`
      const builtUser = await this.buildUser(
        this.userId,
        this.name,
        this.phone,
        this.email,
        this.password,
        this.selectedOptionUser,
        this.selectedOptionCompany,
        this.isSlackNotify,
        this.isTelegramNotify,
        this.isAppNotify,
        this.originatingSearch,
        this.inactive
      )

      try {
        const response = await axios({
          url,
          method: 'put',
          data: builtUser,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200 || response.status == 201) {
          this.isLoading = false
          await this.$alert('Usuário Atualizado!', 'Sucesso', 'success')
          this.$router.push('users-list')
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else {
          this.isLoading = false
          await this.$alert(`Falha ao cadastrar o usuário! ${error.response.data.message}`, 'Error', 'error')
        }
        console.log({
          error
        })
      }
    }
  },

  async updated() {
    this.state = await this.verifyState()
  },

  computed: {
    nameState() {
      return this.name ? true : false
    },
    phoneState() {
      return this.phone ? true : false
    },
    passwordState() {
      return this.password ? true : false
    },
    passwordConfirmedState() {
      return this.passwordConfirmed ? true : false
    },
    emailState() {
      return this.email ? true : false
    },
    typeUserState() {
      return this.selectedOptionUser ? true : false
    },
    divisionState() {
      return this.selectedOptionCompany || this.organizationDivision ? true : false
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')
    this.readUserParams()
    this.state = this.verifyState()

    this.typeUser = localStorage.getItem('typeUser')
    await this.filterOptionUser(this.typeUser)

    this.isLoading = false
  }
}
