import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../components/PaginationNav.vue";
import PaginationMobile from "../../../components/PaginationMobile.vue"
import LoadingComp from "../../../components/LoadingComp.vue";


export default {
  name: "UsersList",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 12,
      pagination: {},
      paginationRange: 11,
      token: "",
      itemsUsers: [],
      users: "",
      password: "",
      newPassword: "",
      newPasswordConfirmed: "",
      currentName: "",
      currentPhone: "",
      currentEmail: "",
      currentPassword: "",
      state: null,
      name: "",
      phone: "",
      email: "",
      paramsUser: {},
      paramsUserPermission: {},
      paramsUserManager: {},

      fields: [{
          key: "showUser",
          label: "Visualizar"
        },
        {
          key: "inactive",
          label: "Ativo",
        },
        {
          key: "permissions",
          label: "Permissões",
        },
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "Nome",
        },

        {
          key: "email",
          label: "E-Mail",
        },
        {
          key: "phone",
          label: "Telefone",
        },
        {
          key: "typeDescription",
          label: "Tipo",
        },
        {
          key: "divisionId",
          label: "Divisão",
        },
        {
          key: "isSlackNotify",
          label: "Notificar Slack",
        },
        {
          key: "isTelegramNotify",
          label: "Notificar Telegram",
        },
        {
          key: "telegramChatId",
          label: "Telegram ID",
        },
        {
          key: "telegramToken",
          label: "Telegram Token",
        },
        {
          key: "isAppNotify",
          label: "Notificar App",
        }
      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "Nome",
        },
        {
          value: 1,
          text: "E-mail",
        },
        {
          value: 2,
          text: "Telefone",
        },
      ],
      pagePagination: null,
      totalRecords: 0,
      isBusy: false
    };
  },

  methods: {
    async clearLocalStoragePage() {
      localStorage.removeItem('currentPageUsers');
      localStorage.removeItem('selectedOptionFilterUser');
      localStorage.removeItem('filterUser')
    },

    async redirect(ref) {
      if (ref == 'users-manager') {
        localStorage.removeItem('redirectUser');
        localStorage.setItem('redirectUser', 'users-list');
      } else {
        await this.clearLocalStoragePage();
      }
      this.$router.push(ref);
    },

    async buildParamsUserManager(itemsUser) {
      this.paramsUserManager = {
        userId: itemsUser.id,
        name: itemsUser.name,
        inactive: itemsUser.inactive,
        email: itemsUser.email,
        phone: itemsUser.phone,
        type: itemsUser.type,
        typeDescription: itemsUser.typeDescription,
        divisionId: itemsUser.divisionId,
        isAppNotify: itemsUser.isAppNotify,
        isSlackNotify: itemsUser.isSlackNotify,
        isTelegramNotify: itemsUser.isTelegramNotify,
        isWhatsAppNotify: itemsUser.isWhatsAppNotify,
        whatsApp: itemsUser.whatsApp,
        originatingSearch: true,
      }

      localStorage.removeItem('redirectUser');
      localStorage.setItem('redirectUser', 'users-list');

      this.$router.push({
        name: 'UsersManager',
        params: this.paramsUserManager
      });
    },

    async buildParamsUserPermission(routeName, itemsUser) {

      localStorage.removeItem('redirectUserPermissions')
      localStorage.setItem('redirectUserPermissions', 'users-list')

      this.paramsUserPermission = {
        userId: itemsUser.id,
        userName: itemsUser.name,
        page: 'usersList'
      }

      this.$router.push({
        name: routeName,
        params: this.paramsUserPermission
      });
    },

    async navigate(page) {
      await this.getUsers(page);
    },

    focus(id) {
      if (id === 'filterInput') {
        this.users = ''
      }
      document.getElementById(id).focus();
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
      });
    },

    buildParams(option, page) {
      let params = new Object();

      if (option == null) {
        params.name = this.users;
      }

      if (option == 1) {
        params.email = this.users;
      }

      if (option == 2) {
        params.phone = this.users;
      }

      params.pageSize = this.perPage;
      params.page = page;

      return params;
    },

    cleanSearch() {
      this.users = "";
      this.getUsers(this.currentPage);
    },

    async getUsers(page) {
      this.isBusy = true;

      const url = `${config.default.urlBase}/users/search`;
      const params = this.buildParams(this.selectedOption, page);

      localStorage.setItem('currentPageUsers', page);
      localStorage.setItem('selectedOptionFilterUser', this.selectedOption)
      localStorage.setItem('filterUser', this.users)

      try {
        const response = await axios({
          url,
          method: "get",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isBusy = false;

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.itemsUsers = response.data.items;
          this.totalRecords = response.data.records;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isBusy = false;
        this.isLoading = false;

        this.itemsUsers = [];
        this.pagination = [];
        this.totalRecords = 0;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    }
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");


    if (localStorage.getItem('selectedOptionFilterUser') != null && localStorage.getItem('selectedOptionFilterUser') != 'null') {
      this.selectedOption = localStorage.getItem('selectedOptionFilterUser');
    } else {
      this.selectedOption = null
    }

    if (localStorage.getItem('filterUser') != null && localStorage.getItem('filterUser') != 'null') {
      this.users = localStorage.getItem('filterUser')
    } else {
      this.users = ''
    }

    this.pagePagination = parseInt(localStorage.getItem('currentPageUsers'))

    if (!this.pagePagination) {
      await this.getUsers(this.currentPage);
    } else {
      await this.navigate(this.pagePagination)
    }


    this.isLoading = false;
  },
};